export default function () {
    const allBanners = document.querySelectorAll(".video-banner");
    if (!allBanners) return;


    const regexCheck = /(?:vimeo\.com\/(?:video\/)?|vimeo\.com\/)(?:channels\/\w+\/)?(\d+)/;
    allBanners.forEach(banner => {
        let passesRegex = banner.href.match(regexCheck);
        if (!passesRegex) return;
        const videoID = passesRegex[1];

        banner.classList.add("has-video");
        banner.addEventListener('click', function (event) {

            event.preventDefault();
            if (banner.classList.contains("playing")) return;
            banner.classList.add("playing");

            const iframeHTML = `
                <iframe src="https://player.vimeo.com/video/${videoID}?autoplay=1" width="707" height="397"
                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            `;
            banner.insertAdjacentHTML('beforeend', iframeHTML);
        });
    });
}