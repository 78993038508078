import Cookies from 'js-cookie';
export default function () {

    const topBar = document.querySelector('.top-bar');

    if (topBar) {
        const closeButton = topBar.querySelector('.top-bar-close');
        closeButton.addEventListener('click', function () {
            topBar.style.display = 'none';
            Cookies.set('top_bar', '1', { expires: 1 });
        });
    }
}