import { isVisible } from './utils/utils';
import * as basicLightbox from 'basiclightbox';

export default function () {
    const allVideoTriggers = document.querySelectorAll('.product-images-mini .play-vimeo-movie');
    if (allVideoTriggers.length === 0) return;

    allVideoTriggers.forEach(trigger => {
        checkUrl(trigger);
    });
}

function checkUrl(trigger) {
    const regexCheck = /(?:vimeo\.com\/(?:video\/)?|vimeo\.com\/)(?:channels\/\w+\/)?(\d+)/;
    let passesRegex = trigger.getAttribute('data-url').match(regexCheck);
    if (!passesRegex) {
        trigger.remove();
        return;
    }

    const videoID = passesRegex[1];

    trigger.addEventListener('click', e => {
        e.preventDefault();
        playVideo(videoID);
    });
}

function playVideo(videoID) {

    const instance = basicLightbox.create(`
        <div class="vimeo-container">
            <iframe src="https://player.vimeo.com/video/${videoID}?autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
    `, {
        onClose: () => {
            window.currentModalInstance = null;
        }
    });

    window.currentModalInstance = instance;

    instance.show();
}

