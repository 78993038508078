export default function () {

    const detailHeadline = document.querySelector('.detail-headline');
    const stickyName = document.querySelector(".sticky-detail-name");

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio < 1) {
                stickyName.innerText = detailHeadline.innerText;
            } else {
                stickyName.innerText = "";
            }
        });
    }, {
        threshold: [1.0]
    });

    observer.observe(detailHeadline);
}