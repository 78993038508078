import { Navigation, Autoplay } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        const anySwiper = document.querySelector('.swiper:not(.product-images)');

        if (!anySwiper) return;

        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN BANNER
            const mainBanner = new Swiper('.main-banner', {
                modules: [Navigation, Autoplay],
                preloadImages: false,
                autoplay: {
                    delay: 7000
                },
                lazy: true,
                loop: true,
                navigation: {
                    nextEl: '.nav-buttons-wrapper .swiper-button-next',
                    prevEl: '.nav-buttons-wrapper .swiper-button-prev'
                },
                on: {
                    beforeTransitionStart: function (instance) {
                        const currentSlide = instance.slides[instance.activeIndex];
                        const slideImg = currentSlide.querySelector('img');

                        if (slideImg.hasAttribute('src')) return;

                        currentSlide.classList.add('loaded');
                        slideImg.setAttribute('src', slideImg.getAttribute('data-src'));
                    }
                }
            });

            //PRODUCERS
            const producersBanner = new Swiper('.producers-banner .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.producer-button',
                    prevEl: '.swiper-button-prev.producer-button'
                },
                loop: false,
                spaceBetween: 32,
                breakpoints: {
                    1: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    601: {
                        slidesPerView: 3
                    },
                    1100: {
                        slidesPerView: 4
                    },
                    1280: {
                        slidesPerView: 5
                    },
                    1461: {
                        slidesPerView: 6
                    }
                }
            });
        });
    }, false);
}