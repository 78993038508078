import Cookies from 'js-cookie';
import { on } from 'delegated-events';

const MAX_ITEMS = 3;

let comparisonCookie = Cookies.get('comparison-cookie');
let array = comparisonCookie ? JSON.parse(comparisonCookie) : [];

const drawer = document.createElement('div');
drawer.innerHTML = `
    <b>Dodano do porównania</b>
    <button title="zamknij okno" class="remove-all"></button>
    <div class="products-wrapper"></div>
    <a class="confirm-button comparison-link" title="przejdź do porównania" href="/comparison">Porównaj</a>`;
drawer.classList.add('comparison-drawer');

export default function () {
    updateDrawer();
    initButtons();
}

function initButtons() {
    document.querySelectorAll('button[data-comparison-id]').forEach(item => {
        item.addEventListener('click', function () {
            const pid = this.getAttribute('data-comparison-id');
            const pimg = checkPageType(item);
            const foundPid = array.find(arrayItem => Number(arrayItem.id) === Number(pid));

            if (foundPid) {
                let index;

                array.some(function (elem, i) {
                    return Number(elem.id) === Number(pid) ? (index = i, true) : false;
                });

                array.splice(index, 1);
            } else {
                if (array.length >= MAX_ITEMS) {
                    array.shift();
                }

                array.push({
                    id: pid,
                    img: pimg,
                    date: new Date().getTime()
                });
            }

            array.sort(function (a, b) { return (a.date > b.date) ? 1 : b.date > a.date ? -1 : 0; });
            Cookies.set('comparison-cookie', JSON.stringify(array), { expires: 30 });

            updateDrawer();
        });
    });
}

function checkPageType(item) {
    const isDetail = document.querySelector("[data-type=detail]");
    if (isDetail) return document.querySelector(".size-full img").currentSrc;
    return item.parentElement.parentElement.querySelector("img").src;
}

on('click', '.comparison-drawer .products-wrapper .remove', function (e) {
    const pid = e.currentTarget.getAttribute('data-single-pid');
    const foundPid = array.find(arrayItem => Number(arrayItem.id) === Number(pid));

    if (foundPid) {
        let index;

        array.some(function (elem, i) {
            return Number(elem.id) === Number(pid) ? (index = i, true) : false;
        });

        array.splice(index, 1);
    }

    if (array.length === 0) {
        drawer.remove();
    }

    updateDrawer();
    Cookies.set('comparison-cookie', JSON.stringify(array), { expires: 30 });
});
on('click', '.comparison-drawer .remove-all', function () {
    array = [];
    updateDrawer();
    Cookies.set('comparison-cookie', JSON.stringify(array), { expires: 30 });
});


function updateDrawer() {
    if (array.length === 0) {
        drawer.remove();
        updateButtonsState();
        return;
    }

    const drawerProductsWrapper = drawer.querySelector('.products-wrapper');

    drawerProductsWrapper.innerHTML = '';

    array.forEach(item => {
        const drawerProduct = `
            <figure class="single-product">
                <img src="${item.img}" alt="zdjęcie produktu do porównania" />
                <i data-single-pid="${item.id}" class="remove"></i>
            </figure>`;
        drawerProductsWrapper.insertAdjacentHTML('afterbegin', drawerProduct);
    });

    const drawerLink = drawer.querySelector('a');

    if (!document.querySelector('.comparison-drawer')) {
        document.querySelector('.inner-wrapper').insertAdjacentElement('beforeend', drawer);
    }

    updateButtonsState();
}
function updateButtonsState() {

    document.querySelectorAll('button[data-comparison-id]').forEach(button => {
        const pid = button.getAttribute('data-comparison-id');
        const foundPid = array.find(arrayItem => Number(arrayItem.id) === Number(pid));

        if (foundPid) {
            button.classList.add('existing');
        } else {
            button.classList.remove('existing');
        }
    });

    document.querySelectorAll('.comparison-link').forEach((link) => {
        if (array.length > 1) {
            link.classList.add('has-two-products');
        } else {
            link.classList.remove('has-two-products');
        }
    });
}