import showPopup from './modals';
export default function () {
    const shippingPopupTrigger = document.querySelector(".shipping-cost-button");
    if (!shippingPopupTrigger) return;
    const shippingPopupBody = document.querySelector(".shipping-popup-contents");
    shippingPopupBody.remove();

    shippingPopupTrigger.addEventListener("click", () => {
        showPopup({
            header: 'Koszty Dostawy',
            body: shippingPopupBody.innerHTML,
            overloadClass: 'user-custom-content'
        });
    });
}